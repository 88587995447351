@use "../base/variables" as *;

.header {
  position: fixed;
  width: 100%;
  top: 0;
  transition: 0.3s ease-in;

  font-family: "Marcellus SC";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 82.89%;
  z-index: 1000;

  .nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    padding: 30px 20px 0 20px;

    .home-button {
      cursor: pointer;
    }

    .navbar-flex {
      display: flex;
      position: relative;
      align-items: center;
      gap: 10px;

      a {
        font-size: 27px;
      }

      .navbar-burger-menu {
        display: flex;
        flex-direction: column;
      }
    }

    .social-icons {
      margin-right: 15px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          color: $red;
          font-size: 27px;
        }
      }
    }
  }

  .navbar-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    z-index: -1;

    width: 100vw;
    height: 100vh;

    position: fixed;
    top: 0;
    /* min(x, y) If space smaller show x if space bigger use y*/
    padding: min(25vh, 2rem) 1rem;

    background: hsl(0 0% 0% / 0.75);
    /*If blur not supported gonna use background above this */
    @supports (backdrop-filter: blur(0.5rem)) {
      background: hsl(0 0% 100% / 0.2);
      backdrop-filter: blur(0.5rem);
    }

    .navbar-link {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 20px;

      cursor: pointer;

      font-size: 47px;
    }

    a {
      position: relative;
    }

    a::before {
      content: "";
      position: absolute;
      width: 90%;
      height: 4px;
      border-radius: 4px;
      background-color: $red;
      bottom: 0;
      left: 5%;
      transform-origin: right;
      transform: scaleX(0);
      transition: transform 0.3s ease-in-out;
    }

    a:hover::before {
      transform-origin: left;
      transform: scaleX(1);
    }

    animation-duration: 2s;
    animation-name: slidein;

    @keyframes slidein {
      from {
        margin-bottom: 100%;
        height: 300%;
      }

      75% {
        font-size: 300%;
        margin-bottom: 25%;
        height: 150%;
      }

      to {
        margin-bottom: 0%;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .header {
    nav {
      .home-button {
        font-size: 12px;
      }
    }
  }
}
