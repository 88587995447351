@use "../base/variables" as *;

.about {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
    
    height: 100vh;
    padding-top: 10px;
    background-color: rgb(44, 58, 53);
  }