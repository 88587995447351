@use "../base/variables" as *;

.main-component {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding-left: 12%;
  height: 100vh;
  margin-bottom: 10px;

  .welcome-img {
    overflow: hidden;
    max-width: 80vh;
    margin-left: -15%;
    z-index: 1;
  }

  .welcome-text {
    width: 600px;
    z-index: 2;
  }

  span {
    opacity: 0;
    animation: reveal 0.25s forwards var(--delay);
  }

  @keyframes reveal {
    from {
      translate: 0 20px;
    }
    to {
      translate: none;
      opacity: 1;
    }
  }

  h1 {
    font-family: "Libre Caslon Text", serif;
    font-style: normal;
    font-weight: 400;
    font-size: 110px;
    line-height: 82.39%;
  }

  h3 {
    font-family: "Marcellus SC";
    font-style: normal;
    font-weight: 400;
    font-size: 35px;
    line-height: 82.89%;
    color: #{$red};
  }

  p {
    margin-top: 50px;
    font-family: "Marcellus SC";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 82.89%;
  }

  @media screen and (max-width: 2100px) {
    .welcome-img {
      margin-left: -15%;
    }
  }

  @media screen and (max-width: 768px) {
    align-items: center;
    padding: 0 20px;

    .welcome-img {
      margin-left: -50%;

      img {
        margin-left: 5rem;
      }
    }

    h1 {
      padding-top: 20%;
      font-size: 58px;
    }

    h3 {
      font-size: 18px;
    }

    p {
      font-size: 15px;
    }
  }
}
