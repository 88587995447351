/* Base imports*/
@use "./base/variables" as *;
@use "./base/reset";
/* Component Imports*/
@use "./components/header";
@use "./components/main";
@use "./components/about";
@use "./components/sidebar";

/* Font imports*/
@import url("https://fonts.googleapis.com/css2?family=Libre+Caslon+Text:wght@400;700&family=Marcellus+SC&display=swap");

.app,
body {
  color: $white;
  background-color: $black;
  position: relative;
  height: 100vh;
}

// Scroll beatween sections
//     =============
.home-view {
  height: 100vh;
  overflow-y: scroll;
  scroll-snap-type: y mandatory;

  div {
    scroll-snap-align: start;
  }

  //Hide scrollbar for Chrome, Safari and Opera
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
//   =============

//Hide scrollbar  for IE, Edge and Firefox
.home-view::-webkit-scrollbar {
  display: none;
}

.contact {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100vh;
  padding-top: 10px;
  background-color: rgb(74, 83, 65);
  margin-bottom: 10px;
}

.portfolio {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10%;

  height: 100vh;
  padding-top: 10px;
  background-color: rgb(65, 66, 83);
  margin-bottom: 10px;

  overflow: hidden;
  gap: 2rem;

  .portfolio-card {
    display: grid;

    // flex-direction: column;
    // align-items: center;
    // justify-content: center;

    background-color: $white;
    color: $black;
  }
}
