@use "../base/variables" as *;

.side-bar {
  color: #{$red};
  width: 10%;
  padding-left: 40px;

  .sidebar-ul {
    position: fixed;
    top: 50%;
    list-style-type: none;
  }

  .lineActive {
    width: 55px;
    height: 20px;
    border-bottom: 3px solid #{$red};
  }

  .line {
    width: 35px;
    height: 20px;
    border-bottom: 3px solid #{$red};

    span {
      opacity: 0;
    }
  }

  .scroll-down {
    transform: rotate(90deg);
    position: fixed;
    left: 0;
    bottom: 15%;
    transition: all 1s;

    p {
      color: #{$white};
      font-family: "Marcellus SC";
      font-style: normal;
      font-weight: 400;
      font-size: 25px;
      line-height: 82.89%;
    }

    p:after {
      content: "";
      display: block;
      width: 150px;
      height: 2px;
      background: white;
      left: 100%;
      top: 50%;
      position: absolute;
      margin-left: 5px;
    }
  }
}
@media screen and (max-width: 768px) {
  .side-bar {
    position: fixed;
    bottom: 0;
    height: 100px;
    width: 100%;

    .sidebar-ul {
      position: absolute;
      bottom: 10px;
      right: 50%;
      margin: 0 auto 50vh;
      transform: translateY(-50%);
      transform: rotate(-90deg);
    }

    .scroll-down {

      p {
        font-weight: 400;
        font-size: 18px;
        line-height: 82.89%;
      }

      p:after {
        width: 100px;
        height: 2px;
      }
    }
  }
}
